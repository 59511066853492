import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';

import { useLocale } from '@app/hooks';
import { Layout, Page as P, Sidebar, Seo, Icons } from '@app/components';

type PagePropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        path: string;
        parentMenu: string;
        date: string;
        head?: boolean;
        image: {
          publicURL: string;
        };
      };
      html: string;
    };
  };
};

const Page: React.FC<PagePropTypes> = ({ data }) => {
  const SCOPE_OPTIONS = {
    scope: 'templates.page',
  };
  const { t } = useLocale();

  const { frontmatter, html } = data.markdownRemark;

  if (data.markdownRemark === null || html === '') {
    navigate('/404');
  }

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      {frontmatter.head && (
        <P.Head
          title={frontmatter.title}
          date={frontmatter.date}
          image={frontmatter.image?.publicURL}
          breadcrumb={[
            { path: '', name: t('labels.home', SCOPE_OPTIONS) },
            {
              path: `/${frontmatter.parentMenu}`,
              name: frontmatter.parentMenu && frontmatter.parentMenu.replace('-', ' '),
              hidden: frontmatter.parentMenu === null,
            },
            { path: `/${frontmatter.path}`, name: frontmatter.title },
          ]}
        />
      )}

      <div className="post-content">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-4 gap-14 items-start">
            <div className="lg:col-span-4">
              <div
                className="py-16 prose lg:prose-lg max-w-none space-y-8"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              {/*<div className="flex justify-center gap-x-2 mt-4 mb-16">
                  <a
                    href="https://www.facebook.com/clinicsbbinternational/"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex rounded-full items-center justify-center text-center text-white text-[28px] bg-black w-[60px] h-[60px] hover:bg-primary"
                  >
                    <Icons.Facebook />
                  </a>
                  <a
                    href="https://twitter.com/Clinicsbbintl"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex rounded-full items-center justify-center text-center text-white text-[28px] bg-black w-[60px] h-[60px] hover:bg-primary"
                  >
                    <Icons.Twitter />
                  </a>
                  <a
                    href="https://www.instagram.com/clinicsbbinternational/"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex rounded-full items-center justify-center text-center text-white text-[28px] bg-black w-[60px] h-[60px] hover:bg-primary"
                  >
                    <Icons.Instagram />
                  </a>
                </div>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $slug }, language: { eq: $language }, type: { eq: "page" } }
    ) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY", locale: $language)
        path
        parentMenu
        head
        image {
          publicURL
        }
      }
      html
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
